<template>
    <v-menu
        ref="menuMes"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
            >
                <v-icon>mdi-calendar-month</v-icon>
            </v-btn>

        </template>
            <v-date-picker
                v-model="esteMes"
                type="month"
                @change="menu=false"
                :max="moment(new Date()).format('YYYY-MM')"
            >
            <v-spacer/>

            <v-btn color="primary" @click="cambiaMes">Este mes</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props:{
        mes:String,
    },
    data:()=>({
        menu:false,
    }),
    methods:{
        cambiaMes(){
            this.menu=false
            this.setHoy()
        },
        setHoy(){
            this.esteMes = this.moment(new Date()).format('YYYY-MM')
        },
    },
    computed:{
        esteMes:{
            get(){return this.mes},
            set(v){this.$emit('update:mes',v)}
        },
    },
}
</script>

<style>

</style>